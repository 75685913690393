import React from "react";
import * as dataset from "../assets/goth-names.json";

function getName(wordList) {
    const name = wordList[getRandomInt(wordList.length - 1)];
    return (name.charAt(0).toUpperCase() + name.slice(1));
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

const GothNameGenerator = ({ typeStyle }) => {
    const { adjective, noun } = dataset.default[0];
    let prefix = "";
    let suffix = "";
    let divider = " ";
    let fname = "";
    let lname = "";

    if (typeStyle === "screenname") {
        prefix = "xX";
        suffix = "Xx";
        divider = "";
        fname = getName(adjective);
        lname = getName(noun);
    } else if (typeStyle === "name") {
        prefix = "";
        suffix = "";
        divider = " ";
        fname = getName(adjective);
        lname = getName(noun);
    } else if (typeStyle === "brianify") {
        prefix = "";
        suffix = " lookin ass";
        divider = " ";
        fname = getName(adjective);
        lname = getName(noun);
    } else {
        fname = "";
        lname = "";
    }

    return (
        <h2 className="result">{prefix}{fname}{divider}{lname}{suffix}</h2>
    );
};

export default GothNameGenerator;
