
import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import GothNameGenerator from "../components/goth-generator";

import "./goth-name-maker.css";

import * as gothLogo from "../assets/goth-name-generator.svg";
import * as aol from "../assets/aol-guy.svg";
import * as bat from "../assets/bat.svg";
import * as star from "../assets/star-of-david.svg";

function fakeAssTimer(setLoading, cb, val) {
    setLoading(true);
    setTimeout(() => {
        cb(val);
        setLoading(false);
    }, 500);
}

const GothNameMakerPage = () => {
    const [nameType, setNameType] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <Layout>
            <SEO title="Goth Name Maker" />

            <img className="goth-logo" src={gothLogo} />

            <div className="title">
                <h1><span>You're ready to embrace</span><br/>
                    your inner goth</h1>
            </div>

            <p className="intro">After reading issue 4, there is no doubt you’re dying to know what your goth name is. We’ve given you a variety of names to catfish someone as gullible as Titzi by using our goth generator. Have fun and die happy.</p>

            <div>
                {loading
                    ? <h2 className="result">Consulting the Underlords...</h2>
                    : <GothNameGenerator typeStyle={nameType}/>
                }


                <div className="button-group">
                    <button className="gbutton" onClick={() => fakeAssTimer(setLoading, setNameType, 'screenname')}>
                        <img src={aol} alt=""/>
                        Screen Name
                    </button>
                    <button className="gbutton" onClick={() => fakeAssTimer(setLoading, setNameType, 'name')}>
                        <img src={bat} alt=""/>
                        Goth Name
                    </button>
                    <button className="gbutton" onClick={() => fakeAssTimer(setLoading, setNameType, 'brianify')}>
                        <img src={star} alt=""/>
                        Brianified Name
                    </button>
                </div>
            </div>
        </Layout>
    );
}

export default GothNameMakerPage;
